import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import CustomTable from "../../components/custom-table/custom-table";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { setModalContent } from "../../store/utilsSlice";
import { PrimaryButton } from "../../styles/PrimaryButton";
import { PageContainer } from "../../styles/PageContainer";
import { lineOverviewColumns } from "./lineConfigurationConfig";
import AddLineConfigurationModal from "../../modals/add-line-configuration-modal";
import ConfirmationModal from "../../modals/confirmation-modal";
import { toast } from "react-toastify";
import { ContentContainerInner } from "../main-layout/main-layout";
import {
  deleteCoMailFacility,
  deleteCoMailFacilityLineConfig,
  getCoMailFacilityList,
} from "../../store/coMailFacilitySlice";
import CustomLoader from "../../components/custom-loader/custom-loader";
import SortLines from "../../modals/sort-lines";
import SortIcon from "../../assets/images/sort.svg";
import AddFacilityModal from "../../modals/add-facility-modal";
import { BorderButton } from "../../styles/BorderButton";
import { HoverBorderButton } from "../../styles/HoverBorderButton";
import { GeneralToast } from "../../utils/toasts";

let TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props: any) => `1px solid ${props.theme.colors.lightGray}`};
`;

let SortButton = styled(Button)`
  padding: 4px 8px 4px 0;
  border: 1px solid #bcbcbc;
  background: none;
  color: #67827d;
  text-transform: none;
`;

function LineConfigurationPage() {
  const coMailFacilities = useSelector(
    (state: RootState) => state.coMailFacility.coMailFacilities
  );
  const getCoMailFacilitiesStatus = useSelector(
    (state: RootState) => state.coMailFacility.getCoMailFacilitiesStatus
  );
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<any>(false);
  const [pageQueryParams, setPageQueryParams] = useState<any>(null);
  const columns = useMemo(() => lineOverviewColumns, []);
  const location = useLocation();
  const navigate = useNavigate();
  const modalContent = useSelector(
    (state: RootState) => state.utils.modalContent
  );
  const dispatch = useAppDispatch();
  const tenantConfiguration = useSelector(
    (state: RootState) => state.core.tenantDetails?.configuration
  );

  useEffect(() => {
    setData(coMailFacilities);
  }, [coMailFacilities]);

  useEffect(() => {
    dispatch(
      getCoMailFacilityList({
        queryParams: queryString.parse(location.search),
      })
    );
  }, []);

  useEffect(() => {
    if (getCoMailFacilitiesStatus === "loading") {
      if (!isLoading) {
        setIsLoading(true);
      }
    } else {
      if (isLoading) {
        setIsLoading(false);
      }
    }
  }, [getCoMailFacilitiesStatus]);

  const onDeleteLineCallback = async (
    data: any,
    cell: any,
    facilityPk: number
  ) => {
    if (data.status) {
      let result: any = await dispatch(
        deleteCoMailFacilityLineConfig({
          facilityPk: facilityPk,
          lineConfigPk: cell.row.original.pk,
        })
      );
      if (deleteCoMailFacilityLineConfig.fulfilled.match(result)) {
        dispatch(
          getCoMailFacilityList({
            queryParams: queryString.parse(location.search),
          })
        );
        toast.success("Line Config has been deleted", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Problem during deleting line", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          progress: undefined,
          theme: "light",
        });
      }
    }
    setShowConfirmationModal(false);
  };

  const handleRowClick = async (e: any, cell: any, facilityPk: number) => {
    e.preventDefault();
    e.stopPropagation();
    const customAttribute: string | null =
      e.target.getAttribute("data-custom-action");
    if (!customAttribute) return;
    if (customAttribute === "edit") {
      dispatch(
        setModalContent(
          <AddLineConfigurationModal
            facilityPk={facilityPk}
            onClose={onCloseAddLineModal}
            line={{
              pk: cell.row.original.pk,
            }}
          />
        )
      );
    } else if (customAttribute === "remove") {
      setShowConfirmationModal(
        <ConfirmationModal
          text={"Do you really want to remove this config line?"}
          header={"Confirmation"}
          onClose={(result: any) =>
            onDeleteLineCallback(result, cell, facilityPk)
          }
        />
      );
    } else {
    }
  };

  const onCloseAddLineModal = (payload: any) => {
    dispatch(setModalContent(null));
    if (payload.status) {
      dispatch(
        getCoMailFacilityList({
          queryParams: queryString.parse(location.search),
        })
      );
    }
  };

  const onCloseAddFacilityModal = (payload: any) => {
    dispatch(setModalContent(null));
    if (payload.success) {
      dispatch(
        getCoMailFacilityList({
          queryParams: queryString.parse(location.search),
        })
      );
    }
  };

  const onCloseAddCoMailFacility = (payload: any) => {
    if (payload.status) {
      dispatch(
        getCoMailFacilityList({
          queryParams: queryString.parse(location.search),
        })
      );
    }
  };

  const onRemoveFacility = (facility: any) => {
    setShowConfirmationModal(
      <ConfirmationModal
        text={"Do you really want to remove this facility?"}
        header={"Confirmation"}
        onClose={(result: any) => onDeleteFacilityCallback(result, facility)}
      />
    );
  };

  const onDeleteFacilityCallback = async (data: any, facility: any) => {
    if (data.status) {
      let result = await dispatch(
        deleteCoMailFacility({
          pk: facility.pk,
        })
      );
      if (deleteCoMailFacility.fulfilled.match(result)) {
        GeneralToast.success("Facility has been deleted");
        dispatch(
          getCoMailFacilityList({
            queryParams: queryString.parse(location.search),
          })
        );
      } else {
        GeneralToast.error("Problem during deleting facility");
      }
    }
    setShowConfirmationModal(null);
  };

  const onCloseEditCoMailFacility = (payload: any) => {
    if (payload.status) {
      dispatch(
        getCoMailFacilityList({
          queryParams: queryString.parse(location.search),
        })
      );
    }
  };

  const addLineConfiguration = (facilityPk: number) => {
    if (!modalContent) {
      dispatch(
        setModalContent(
          <AddLineConfigurationModal
            onClose={onCloseAddLineModal}
            facilityPk={facilityPk}
          />
        )
      );
    }
  };

  const addFacility = (payload: any) => {
    if (!modalContent) {
      dispatch(
        setModalContent(
          <AddFacilityModal
            onClose={onCloseAddFacilityModal}
            facility={payload}
          />
        )
      );
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const openSortLineModal = () => {
    if (!modalContent) {
      dispatch(setModalContent(<SortLines onClose={onCloseSortLineModal} />));
    }
  };

  const onCloseSortLineModal = (payload: any) => {
    dispatch(setModalContent(null));
    if (payload.status) {
      dispatch(
        getCoMailFacilityList({
          queryParams: queryString.parse(location.search),
        })
      );
    }
  };

  const generateEntryFileLabel = (facility: any) => {
    return (
      <Box display={"inline-flex"} sx={{ marginTop: "15px" }}>
        <Box sx={{ marginRight: "20px" }}>Entry File:</Box>{" "}
        <Box sx={{ color: "#989898" }}>
          {facility?.entry_list?.file_name || "-"}
        </Box>
      </Box>
    );
  };

  const generateZipRangesLabel = (facility: any) => {
    if (facility.zip_ranges?.length) {
      let zipRanges = "";
      facility.zip_ranges.forEach((zip: any, index: number) => {
        zipRanges += `${zip.start}-${zip.end}${
          index < facility.zip_ranges.length - 1 ? ", " : ""
        }`;
      });
      return (
        <Box display={"inline-flex"} sx={{ marginTop: "15px" }}>
          <Box sx={{ marginRight: "20px" }}>Zip Codes:</Box>{" "}
          <Box sx={{ color: "#989898" }}>{zipRanges}</Box>
        </Box>
      );
    }
    return "";
  };

  // @ts-ignore
  return (
    <ContentContainerInner>
      <PageContainer sx={{ my: 4, mx: 2, p: 3 }}>
        <TopBox
          sx={{
            pb: 2,
          }}
        >
          <div>
            <Typography variant="h5">Line Configuration</Typography>
            <Typography variant="subtitle2">View all lines</Typography>
          </div>
          <div>
            <SortButton onClick={openSortLineModal}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img src={SortIcon} />
                Rearrange
              </Box>
            </SortButton>
          </div>
        </TopBox>

        {isLoading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <CustomLoader />
          </Box>
        )}
        {data.map((facility: any, index) => (
          <div key={facility.pk}>
            <Box
              sx={{ overflow: "auto", mt: 3, mb: 3 }}
              data-testid={"facilities_facility-list_section"}
            >
              <Box
                display={"flex"}
                sx={{ fontWeight: "bold", fontSize: 20 }}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box>
                  <Box
                    data-testid={"facilities_facility-name_label"}
                    sx={{ fontSize: "20px" }}
                  >
                    {facility.name}
                  </Box>
                  {tenantConfiguration?.can_set_zip_ranges && (
                    <Box sx={{ fontSize: "16px" }}>
                      <>{generateEntryFileLabel(facility)}</>
                    </Box>
                  )}
                  <Box sx={{ fontSize: "16px" }}>
                    <>{generateZipRangesLabel(facility)}</>
                  </Box>
                </Box>
                <Box sx={{ ml: 2 }}>
                  <Box display={"flex"}>
                    <HoverBorderButton
                      type={"button"}
                      sx={{ mr: 2 }}
                      textColor={"#9D1719"}
                      borderColor={"transparent"}
                      borderHoverColor={"#9D1719"}
                      onClick={() => onRemoveFacility(facility)}
                      data-testid={"co-mail-facility_remove_button"}
                    >
                      Remove
                    </HoverBorderButton>
                    <BorderButton
                      type={"button"}
                      // sx={{ mr: 2 }}
                      onClick={() => addFacility(facility)}
                      data-testid={"co-mail-facility_edit_button"}
                    >
                      Edit facility
                    </BorderButton>
                  </Box>
                </Box>
              </Box>
              {facility.line_configs?.length ? (
                <CustomTable
                  testId={"line-configuration"}
                  isLoading={isLoading}
                  data={facility.line_configs}
                  columnConfig={columns}
                  queryParams={pageQueryParams}
                  handleRowClick={(e: any, cell: any) =>
                    handleRowClick(e, cell, facility.pk)
                  }
                  updateParams={() => {}}
                  hidePagination={true}
                  totalItems={data.length}
                ></CustomTable>
              ) : (
                <Box
                  textAlign={"center"}
                  sx={{ fontSize: 14, fontWeight: "light" }}
                >
                  No lines yet
                </Box>
              )}
              <Box
                component={"div"}
                display={"flex"}
                justifyContent={"flex-end"}
                sx={{ my: 1, pb: 1, borderBottom: "1px solid #F1F1F1" }}
              >
                <div>
                  <PrimaryButton
                    data-testid={"facilities_add-line-config_button"}
                    type={"button"}
                    sx={{}}
                    onClick={() => addLineConfiguration(facility.pk)}
                  >
                    Add line
                  </PrimaryButton>
                </div>
              </Box>
            </Box>
          </div>
        ))}
        {/*</ReactSortable>*/}

        {getCoMailFacilitiesStatus === "fetched" && (
          <Box component={"div"} display={"flex"} sx={{ my: 1, pb: 1 }}>
            <PrimaryButton
              sx={{ width: "120px" }}
              data-testid={"co-mail-facility_add-facility_button"}
              type={"button"}
              onClick={() => addFacility(null)}
            >
              Add facility
            </PrimaryButton>
          </Box>
        )}
        <Box display={"flex"} justifyContent={"space-between"} sx={{ mt: 4 }}>
          <Box
            sx={{ width: "33%" }}
            display={"flex"}
            justifyContent={"flex-start"}
          >
            <Button onClick={goBack}>Go Back</Button>
          </Box>
        </Box>
        {showConfirmationModal}
      </PageContainer>
    </ContentContainerInner>
  );
}

export default LineConfigurationPage;
