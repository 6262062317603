import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { unusedAddressesColumns } from "./unusedConfig";
import { RootState, useAppDispatch } from "../../store/store";
import { PageContainer } from "../../styles/PageContainer";
import { useSelector } from "react-redux";
import { PaginatorModel } from "../../models/PaginatorModel";
import CustomUnusedAddressTable from "../../components/custom-unused-address-table/custom-unused-address-table";
import { getAdressesList } from "../../store/addressesSlice";
import React from "react";
import CustomLoader from "../../components/custom-loader/custom-loader";

let TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props: any) => `1px solid ${props.theme.colors.lightGray}`};
`;

let ContentContainerInner = styled.div({
  flexGrow: 1,
  maxWidth: "1800px",
  width: "100%",
  position: "relative",
});

function UnusedPage() {
  const unusedAddresses = useSelector(
    (state: RootState) => state.address.unusedAddresses
  );
  const unusedAddressesStatus = useSelector(
    (state: RootState) => state.address.unusedAddressesStatus
  );
  const [data, setData] = useState<Array<any>>([]);
  const [pageQueryParams, setPageQueryParams] = useState<PaginatorModel | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const columns = useMemo(() => unusedAddressesColumns, []);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pendingPromises = useRef(0);

  useEffect(() => {
    if (unusedAddressesStatus === "loading") {
      if (!isLoading) {
        setIsLoading(true);
      }
    } else {
      if (isLoading) {
        setIsLoading(false);
      }
    }
  }, [unusedAddressesStatus]);

  useEffect(() => {
    setData(unusedAddresses);
  }, [unusedAddresses]);

  useEffect(() => {
    dispatch(getAdressesList({}));
    return () => {};
  }, []);

  const handleRowClick = (e: any, cell: any) => {
    e.preventDefault();
    e.stopPropagation();
    const customAttribute: string | null =
      e.target.getAttribute("data-custom-action");
    const readonlyAttribute: boolean | null =
      e.target.getAttribute("data-readonly") === "true";
    if (!customAttribute || readonlyAttribute) return;
  };

  const onCancel = () => {
    navigate("/overview");
  };

  return (
    <ContentContainerInner>
      <PageContainer sx={{ my: 4, mx: 2, p: 3 }}>
        <TopBox
          sx={{
            pb: 2,
          }}
        >
          <div>
            <Typography variant="h5">Unused address files</Typography>
            {/*<Typography variant="subtitle2">*/}
            {/*  View all recent pools and take actions*/}
            {/*</Typography>*/}
          </div>
        </TopBox>

        {unusedAddressesStatus === "loading" ? (
          <Box
            sx={{
              // border: "1px solid red",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <CustomLoader />
            {/*{row.getVisibleCells().length}*/}
          </Box>
        ) : (
          <React.Fragment>
            {data?.length ? (
              <React.Fragment>
                {data.map((version: any) => (
                  <Box sx={{ overflow: "auto", padding: "5px 5px 10px 5px" }}>
                    <>
                      <CustomUnusedAddressTable
                        isLoading={isLoading}
                        testId={"unusedAddresses-list"}
                        data={[version]}
                        columnConfig={columns}
                        queryParams={pageQueryParams}
                        handleRowClick={handleRowClick}
                        updateParams={() => {}}
                        hidePagination={true}
                        totalItems={data.length}
                      ></CustomUnusedAddressTable>
                    </>
                  </Box>
                ))}
              </React.Fragment>
            ) : (
              <Box textAlign={"center"} sx={{ my: 3 }}>
                No unused files
              </Box>
            )}
          </React.Fragment>
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button data-testid={"summary_cancel_button"} onClick={onCancel}>
            Go back
          </Button>
          <Box sx={{ width: "50px" }}></Box>
        </Box>
      </PageContainer>
    </ContentContainerInner>
  );
}

export default UnusedPage;
